import "./help.css";

export default function Help({
  visible = false,
  doNotShowAgain,
  doShowAgain,
  justClose,
}) {
  return (
    <>
      <section className={`modal${visible ? "" : " hidden"}`}>
        <h2>Welcome</h2>
        <div>
          This is development version of the game, it might have connection related issues.
        </div>
        <br />
        <button onClick={doShowAgain}>
          Ok, understood!
        </button>
        <button onClick={doNotShowAgain}>Don't show me again</button>
      </section>

      <div
        className={`overlay${visible ? "" : " hidden"}`}
        onClick={justClose}
      ></div>
    </>
  );
}
